import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useMetaTags } from '@red-build/leadcentre';
import MuiAlert from '@material-ui/lab/Alert';
import { DealerContext } from '../contexts/DealerContext';
import DealsFilter from './DealsFilter';
import heroBanner from '../assets/OmodaAssets/Rola-Omoda---Banner-1.webp';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
    padding: '0px 0px',
  },
  background: {
    width: '100vw',
    padding: '40px 15px',
    '@media (max-width: 425px)': {
      padding: '10px',
    },
    '@media (min-width: 426px) and (max-width: 1200px)': {
      maxWidth: '20px 5px',
    },
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    textTransform: 'uppercase',
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '30px',
    width: '100%',
    margin: '10px',
    minWidth: 'calc(100% / 3 - 20px)',
    maxWidth: 'calc(100% / 2 - 20px)',
    '@media (max-width: 425px)': {
      maxWidth: '100%',
      padding: '10px',
      margin: '10px',
    },
    '@media (min-width: 426px) and (max-width: 1200px)': {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
  },
  textContainer: {
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
    textAlign: 'center',
  },
  installmentText: {
    fontFamily: 'OmodaR',
    fontSize: '25px',
    fontWeight: '600',
    padding: '0px',
    margin: '0px',
  },
  headlineText: {
    fontFamily: 'OmodaR',
    fontSize: '20px',
    marginBottom: '0px !important',
    textTransform: 'uppercase',
  },
  subHeadText: {
    fontFamily: 'OmodaL',
    fontSize: '26px',
    marginBottom: '0px !important',
  },
  fromText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
    marginBottom: '0px !important',
  },
  infoText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
  },
  labelText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
    fontSize: '15px',
  },
  button: {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    width: '100%',
    height: '70px',
  },
  h1: {
    fontFamily: 'OmodaR',
    fontWeight: 500,
    fontSize: '50px',
    textAlign: 'center',
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default function SpecialDeals() {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [sortDirection, setSortDirection] = useState('Desc');
  const [oemDeals, setOemDeals] = useState([]);
  const [sortOemDeals, setSortOemDeals] = useState([]);
  const [sortRolaDeals, setSortRolaDeals] = useState([]);
  const [rolaDeals, setRolaDeals] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const { globalDealer } = useContext(DealerContext);

  const { deals } = DealsFilter(pageNumber, sortDirection);
  const handlePageChange = (deal) => {
    history.push(`/special-deals/${deal?.offerId}`, deal);
  };
  const handleRedirectUrl = (deal) => {
    window.location.href = `${deal?.redirectUrl}`;
  };
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  useEffect(() => {
    const dealerName =
      globalDealer?.name && typeof globalDealer.name === 'string'
        ? globalDealer.name.replace('Master', '')
        : '';
    updateMetaTags({
      pathname,
      title: ['Rola Omoda Specials'],
      description: [dealerName, 'Omoda', 'Specials'],
      canonical: `https://rolaomoda.co.za${pathname}`,
    });
  }, []);

  useEffect(() => {
    setOemDeals(deals.filter((d) => d?.category !== 'rola'));
    setRolaDeals(deals.filter((d) => d?.category === 'rola'));
  }, [deals]);

  useEffect(() => {
    setSortOemDeals(oemDeals.sort((a, b) => a.priority - b.priority));
  }, [oemDeals]);

  useEffect(() => {
    setSortRolaDeals(oemDeals.sort((a, b) => a.priority - b.priority));
  }, [rolaDeals]);

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  return (
    <div className={classes.root}>
      <img alt="" src={heroBanner} style={{ width: '100%', objectFit: 'cover' }} />

      {/* {sortRolaDeals.length > 0 ? (
        <>
          <h2 className={classes.h1}>ROLA OMODA SPECIALS</h2>
          <div className={classes.background}>
            <div className={classes.cardHolder}>
              {sortRolaDeals.map((deal, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={classes.card}>
                  <img alt="" src={deal?.imageUrl} width="100%" />

                  {deal?.isCashDeal ? (
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p className={classes.headlineText}>{deal.headline}</p>
                        <hr />
                        <p className={classes.installmentText}>
                          <span className={classes.fromText}>For only</span>{' '}
                          {formatter.format(deal.price)}
                        </p>
                        <hr />
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className={classes.headlineText}>{deal.headline}</p>
                      <hr />
                      <p className={classes.installmentText}>
                        <span className={classes.fromText}>From</span>{' '}
                        {formatter.format(deal.monthlyInstallment)}
                        <span className={classes.fromText}> *PM</span>
                      </p>
                      <hr />
                    </div>
                  )}

                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => {
                      handlePageChange(deal);
                    }}
                  >
                    <p style={{ fontSize: '20px', fontFamily: 'OmodaL', fontWeight: 100 }}>
                      View Offer
                    </p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null} */}

      <h2 className={classes.h1}>OMODA & JAECOO SPECIALS</h2>
      <div className={classes.background}>
        <div className={classes.cardHolder}>
          {sortOemDeals.length > 0 ? (
            sortOemDeals.map((deal, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={classes.card}>
                <img alt="" src={deal?.imageUrl} width="100%" />
                {deal?.isCashDeal ? (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {/* is cash deal */}
                      <p className={classes.headlineText}>{deal.headline}</p>
                      <p
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: '2px' }}
                      />
                      <p className={classes.installmentText}>
                        <span className={classes.fromText}>For only</span>{' '}
                        {formatter.format(deal.price)}
                      </p>
                      <p
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: '2px' }}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* is finance deal */}
                    <p className={classes.headlineText}>{deal.headline}</p>
                    <hr />
                    <p className={classes.installmentText}>
                      <span className={classes.fromText}>From</span>{' '}
                      {formatter.format(deal.monthlyInstallment)}
                      <span className={classes.fromText}> *PM</span>
                    </p>
                    <hr />
                  </div>
                )}
                {deal?.redirectUrl ? (
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => {
                      handleRedirectUrl(deal);
                    }}
                  >
                    <p style={{ fontSize: '20px', fontFamily: 'OmodaL', fontWeight: 100 }}>
                      View Offer
                    </p>
                  </button>
                ) : (
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => {
                      handlePageChange(deal);
                    }}
                  >
                    <p style={{ fontSize: '20px', fontFamily: 'OmodaL', fontWeight: 100 }}>
                      View Offer
                    </p>
                  </button>
                )}
                {sortRolaDeals.priority}
              </div>
            ))
          ) : (
            <div className={classes.textContainer}>
              <Alert severity="info">There are currently no available offers.</Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
